import React, { useEffect } from 'react';

const TawkTo = () => {
  useEffect(() => {
    

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/66c646c0ea492f34bc08afe2/1i5r88oid';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    document.body.appendChild(script);

    // Optional: Clean up the script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // This component does not render anything
};

export default TawkTo;
