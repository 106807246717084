import React, { Fragment, useEffect } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  Outlet,
  Navigate,
} from "react-router-dom";
import { fetchSiteSettings } from "./states/actions/fetchSiteSettings";
import { useDispatch, useSelector } from "react-redux";

import AuthRoutes from "./AuthRoutes";
import PrivateRoutes from "./PrivateRoutes";

import Header from "./components/includes/Header";
import Footer from "./components/includes/Footer";

import Login from "./components/pages/login/Index";
import Signup from "./components/pages/signup/Index";
import ForgotPassword from "./components/pages/forgot_password/Index";
import ResetPassword from "./components/pages/reset_password/Index";
import AccountInvitation from "./components/pages/account_invitation/Index";

import Home from "./components/pages/home/Index";
import Pricing from "./components/pages/pricing/Index";
import Blog from "./components/pages/blog/Index";
import BlogDetails from "./components/pages/blog/Blog-details";
import About from "./components/pages/about/Index";
import Help from "./components/pages/help/Index";
import UploadData from "./components/pages/upload/Index";
import EnterpriseCheckout from "./components/pages/enterprise-form/Index";
import Checkout from "./components/pages/checkout/Index";
import SearchFound from "./components/pages/help/Search-found";
import SearchArticle from "./components/pages/help/Search-article";
import PrivacyPolicy from "./components/pages/privacy-policy/Index";
import AccountSettings from "./components/pages/account-settings/Index";
import SearchProperty from "./components/pages/search/Index";
import PropertyListing from "./components/pages/property/Index";
import ResultSearch from "./components/pages/search/Result-search";
import SavedSearches from "./components/pages/saved-searches/Index";
import SearchTable from "./components/pages/search/Search-table";
import Export from "./components/pages/export/Index";
import PropertyDetails from "./components/pages/search/Property-details";
import OwnerProperties from "./components/pages/owner-property/Index";
import OwnerCompanies from "./components/pages/owner-property/Owner-companies";
import OwnerOverview from "./components/pages/owner-property/Owner-overview";
import Support from "./components/pages/support/Index";
import OwnersFilter from "./components/pages/search/Owners-filter";
import ScrollToTop from "./components/common/ScrollToTop";
import Error from "./components/pages/Error";
import Favorites from "./components/pages/favorites";
import Feedback from "./components/pages/feedback";
import Industries from "./components/pages/industries";
import IndustriesDetail from "./components/pages/industries/detail";
import TermsConditions from "./components/pages/terms-conditions/Index";
import SubscriptionAgreement from "./components/pages/subscription-agreement/Index";
import SimpleBlogDetails from "./components/pages/blog/Simple-Blog-details";
import TawkTo from "./tawkto";

function App() {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.fetchSignin.authToken);
  useEffect(() => {
    dispatch(fetchSiteSettings());
  }, [authToken]);

  const site_settings = useSelector(
    (state) => state.fetchSiteSettings.site_settings
  );

  const member = site_settings?.member;

  const PublicLayout = () => (
    <Fragment>
      <Header access="public" member={member} />
      <Outlet />
      <Footer />
    </Fragment>
  );

  const PrivatePagesLayout = ({ footer }) => (
    <Fragment>
      <Header access="logged" member={member} />
      <Outlet />
      {footer && <Footer />}
    </Fragment>
  );

  const AuthPagesLayout = () => (
    <Fragment>
      <Header access="auth" member={member} />
      <Outlet />
    </Fragment>
  );
  const NoHeaderFooter = () => (
    <Fragment>
      <Outlet />
    </Fragment>
  );

  return (
    <Fragment>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          # PUBLIC PAGES
          <Route element={<PublicLayout />}>
            <Route exact path="/" element={<Home />} />
            {/* <Route exact path="/about" element={<About />} /> */}
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/blogs" element={<Blog />} />
            <Route exact path="/feedback" element={<Feedback />} />
            <Route exact path="/industries" element={<Industries />} />
            <Route exact path="/industry/:slug" element={<IndustriesDetail />} />
            <Route
              exact
              path="/blog-detail/:id/:slug"
              element={<BlogDetails />}
            />
            <Route
              exact
              path="/dashboard-blog-detail/:id/:slug"
              element={<SimpleBlogDetails />}
            />
            <Route
              exact
              path="/enterprise-checkout/:plan_id/:plan_slug"
              //element={authToken && authToken != null ? <AuthPagesLayout><EnterpriseCheckout member={member} /></AuthPagesLayout> : <AuthPagesLayout><EnterpriseCheckout member={member} /></AuthPagesLayout> }
              element={<EnterpriseCheckout member={member} />}
            />
            <Route exact path="/support" element={<Support />} />
            <Route exact path="/help" element={<Help />} />
            <Route
              exact
              path="/help-result/:subcat_id"
              element={<SearchFound />}
            />
            <Route
              exact
              path="/question-article/:question_id"
              element={<SearchArticle />}
            />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy page_name="privacy-policy-page" />} />#
            <Route exact path="/terms-conditions" element={<TermsConditions page_name="terms-conditions-page" />} />#
            <Route exact path="/subscription-agreement" element={<SubscriptionAgreement page_name="subscription-agreement-page" />} />#
          </Route>
          # AUTH PAGES
          <Route element={<AuthRoutes />}>
            <Route element={<AuthPagesLayout />}>
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/signup" element={<Signup />} />
              <Route
                exact
                path="/forgot-password"
                element={<ForgotPassword />}
              />
              <Route
                exact
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route
                exact
                path="/account-invitation/:token"
                element={<AccountInvitation />}
              />
            </Route>
          </Route>
          # PRIVATE ROUTES WITHOUT FOOTER
          <Route element={<PrivateRoutes />}>
            # AUTH LAYOUT BUT NOT INCLUDED IN AUTH PAGES
            <Route element={<AuthPagesLayout />}>
              <Route
                exact
                path="/checkout/:plan_id"
                element={
                  authToken && authToken != null ? (
                    <Checkout member={member} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              {/* <Route exact path="/checkout/:plan_id" element={<Checkout />} /> */}

              <Route
                exact
                path="/enterprise-user-checkout/:plan_id"
                element={
                  authToken && authToken != null ? (
                    <Checkout member={member} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
            </Route>
            <Route element={<PrivatePagesLayout />}>
              <Route
                exact
                path="/dashboard"
                element={
                  (authToken && authToken != null) || member?.id > 0 ? (
                    <PropertyListing member={member} />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route exact path="/saved-searches" element={<SavedSearches />} />

              <Route
                exact
                path="/favorites"
                element={
                  (authToken && authToken != null) || member?.id > 0 ? (
                    <Favorites member={member} site_settings={site_settings} />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                }
              />
              <Route
                exact
                path="/account-settings"
                element={
                  (authToken && authToken != null) || member?.id > 0 ? (
                    <AccountSettings
                      member={member}
                      site_settings={site_settings}
                    />
                  ) : (
                    <Navigate to="/dashboard" />
                  )
                }
              />

              <Route
                exact
                path="/search-property"
                element={<SearchProperty />}
              />
              <Route exact path="/search-result" element={<ResultSearch />} />
              <Route exact path="/search-table" element={<SearchTable />} />
              <Route exact path="/export" element={<Export />} />
              <Route
                exact
                path="/property-details/:propertyId"
                element={<PropertyDetails />}
              />
              <Route
                exact
                path="/property-details/:propertyId/:previousUrl"
                element={<PropertyDetails />}
              />
              <Route exact path="/owners" element={<OwnersFilter />} />
              <Route
                exact
                path="/owner-property/:ownerEmail"
                element={<OwnerProperties />}
              />
              <Route
                exact
                path="/owner-overview/:ownerEmail"
                element={<OwnerOverview />}
              />
            </Route>
          </Route>
          # PRIVATE ROUTES WITH FOOTER
          <Route element={<PrivatePagesLayout footer={true} />}>

            <Route exact path="/upload-data" element={<UploadData />} />
          </Route>
          <Route element={<NoHeaderFooter footer={true} />}>
            <Route path="*" element={<Error />} />
            <Route path="/404" element={<Error />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <TawkTo />
    </Fragment>
  );
}

export default App;
