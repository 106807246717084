import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import SubmitButton from "../../common/SubmitButton";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  CardElement,
} from "@stripe/react-stripe-js";

const CSS_STYLES = `
.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
`;

const useOptions = () => {
  const options = useMemo(
    () => ({
      hidePostalCode: true,
      style: {
        base: {
          display: "block",
          width: "100%",
          height: "5.3rem",
          fontFamily: "'Red Hat Display', sans-serif",
          fontWeight: "500",
          color: "#061237",
          background: "#fff",
          "text-align": "left",
          padding: "0.6rem 1.4rem",
          "::placeholder": {
            color: "#130a2952",
            fontSize: "15px",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return options;
};
export default function UpdateCardDetails({ setCardPopup, card_details }) {
  const options = useOptions();
  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [cardError, setCardError] = useState(null);
  const [cardLoading, setCardLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const watchAllFields = watch();
  const handleCardDetailsChange = (ev) => {
    ev.error ? setCardError(ev.error.message) : setCardError();
  };
  const onSubmit = (data, e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="popup lg active lg">
        <div className="table_dv">
          <div className="table_cell">
            <div className="_inner">
              <div className="x_btn" onClick={() => setCardPopup(false)}></div>
              <h6 className="color">Update Card Details</h6>
              <div className="br"></div>
              <form onSubmit={handleSubmit(onSubmit)} className="user_add_form">
                <div className="from_row row">
                  <div className="col-sm-6">
                    <h6>Card Holder Name</h6>
                    <div className="form_blk">
                      <input
                        defaultValue={
                          card_details && card_details?.card_holder_name
                        }
                        type="text"
                        className="input"
                        {...register("card_holder_name", {
                          required: "First Name is required.",
                          minLength: {
                            value: 2,
                            message:
                              "First Name should contains atleast 2 letters.",
                          },
                        })}
                      />
                      <span className="validation-error">
                        {errors.card_holder_name?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <h6>Card Number</h6>
                    <div className="form_blk stripe_wrap">
                      <CardElement
                        options={options}
                        onChange={handleCardDetailsChange}
                      />
                      {cardError && (
                        <p className="validation_error">{cardError}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="btn_blk text-center">
                  <SubmitButton
                    classes="site_btn block"
                    isFormProcessing={isFormProcessing}
                    button_text="Submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
