import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateMEmberCredits } from "../../../states/actions/fetchSiteSettings";
import Text from "../../common/Text";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import http from "../../../helpers/http";
import { doObjToFormData } from "../../../helpers/helpers";
import { toast } from "react-toastify";
export default function TableDataBlk({ val, i }) {
  console.log(val);
  const [isTypeLoading, setIsTypeLoading] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(null);
  const dispatch = useDispatch();
  const ToggleContact = (id) => {
    if (showContactInfo === null) {
      setIsTypeLoading(true);
      http
        .post(
          `request-property-contact/${id}`,
          doObjToFormData({ id_type: "" })
        )
        .then(({ data }) => {
          setIsTypeLoading(false);
          if (data?.status === 1) {
            dispatch(updateMEmberCredits(data?.total_available_credits));
            setShowContactInfo({
              email: data?.email,
              phone: data?.phone,
            });
          } else {
            toast.error(<Text string={data?.msg} />, TOAST_SETTINGS);
          }
        })
        .catch((error) => {
          setIsTypeLoading(false);
        });
    }
  };
  return (
    <>
      <tr key={i + 5612345 + 12789100000000}>
        <td>{val.address}</td>
        <td>{val.last_sale}</td>
        <td>${Number(val.sale_price).toLocaleString()}</td>
        <td>{val.year_built}</td>
        <td>{Number(val.area).toLocaleString()} sqft</td>
        <td>{val.units}</td>
        <td>{val.type}</td>
        <td>
          {showContactInfo !== null &&
          (showContactInfo?.email || showContactInfo?.phone) ? (
            <ul className="social">
              <li>
                <a href={`tel:${showContactInfo?.phone}`}>
                  <img src="/images/call-icon.svg" alt="" />
                </a>
              </li>
              <li>
                <a href={`mailto:${showContactInfo?.email}`}>
                  <img src="/images/email-icon.svg" alt="" />
                </a>
              </li>
            </ul>
          ) : (
            <a
              href="#!"
              className="site_btn sm"
              onClick={() => ToggleContact(val?.encoded_parcel_id)}
              disabled={isTypeLoading}
            >
              View Contact {isTypeLoading ? <i className="spinner"></i> : ""}
            </a>
          )}
        </td>
      </tr>
    </>
  );
}
