import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

const ExportInfo = ({
  properties,
  handleExportPdf,
  isFormProcessing,
  labels,
  searched_properties,
  searchParams,
  total_downloads,
  total_available_credits,
}) => {
  let export_data = localStorage.getItem("export_data");

  const [tabType, setTabType] = useState("property_types");

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const watchAllFields = watch();
  function convertStringToArray(inputString) {
    if (inputString.includes(",")) {
      return inputString.split(",").map((item) => item.trim()); // Trim to remove any extra spaces
    } else {
      return [inputString]; // Return the string as a single element array if no comma is found
    }
  }
  useEffect(() => {
    setValue("file_type", "excel");
  }, [setValue]);

  useEffect(() => {
    if (export_data) {
      export_data = JSON.parse(export_data);
      setValue(
        "property_type",
        export_data?.property_type ? export_data?.property_type : []
      );
      setValue(
        "building_lot",
        export_data?.building_lot ? export_data?.building_lot : []
      );
      setValue(
        "owner_contact_info",
        export_data?.owner_contact_info ? export_data?.owner_contact_info : []
      );
      setValue(
        "property_type",
        export_data?.property_type ? export_data?.property_type : []
      );
      setValue("sales", export_data?.sales ? export_data?.sales : []);
      setValue("tax", export_data?.tax ? export_data?.tax : []);
      setValue("labels", export_data?.labels ? export_data?.labels : []);
      setValue("debt", export_data?.debt ? export_data?.debt : "");
      setValue(
        "distressed",
        export_data?.distressed ? export_data?.distressed : ""
      );
      setValue(
        "file_type",
        export_data?.file_type ? export_data?.file_type : ""
      );
    }
  }, [export_data]);
  // console.log(watchAllFields);
  // useEffect(() => {
  //     let building_lot = []
  //     let owner_contact_info = []
  //     let sales = []
  //     let tax = []
  //     setValue("search_type", searchParams?.search_type ? searchParams?.search_type : "")
  //     setValue("search_query", searchParams?.search_query ? searchParams?.search_query : "")
  //     if (searchParams?.yearbuilt_from) {
  //         building_lot.push("Year built")
  //     }
  //     if (searchParams?.yearbuilt_until) {
  //         building_lot.push("Year built")
  //     }
  //     if (searchParams?.zonedcodelocal) {
  //         building_lot.push("Zoning")
  //     }
  //     if (searchParams?.arealotsf_from) {
  //         building_lot.push("Lot Size")
  //     }
  //     if (searchParams?.arealotsf_until) {
  //         building_lot.push("Lot Size")
  //     }
  //     if (searchParams?.property_type) {
  //         let properties = convertStringToArray(searchParams?.property_type)
  //         if (properties?.includes("Idustrial")) {
  //             properties.push("Industrial")
  //         }
  //         setValue("property_type", properties)
  //     }
  //     if (searchParams?.label_include) {
  //         let label_include = convertStringToArray(searchParams?.label_include)

  //         setValue("labels", label_include)
  //     }
  //     if (searchParams?.owner_info) {
  //         let owner_info = convertStringToArray(searchParams?.owner_info)
  //         if (owner_info?.includes("phone_include")) {
  //             owner_contact_info.push("Contact Phone")
  //         }
  //         if (owner_info?.includes("email_include")) {
  //             owner_contact_info.push("Contact Email")
  //         }
  //         if (owner_info?.includes("mailing_include")) {
  //             owner_contact_info.push("Contact Mailing Address")
  //         }
  //         if (searchParams?.owner_name) {
  //             owner_contact_info.push("Owner Name")
  //         }
  //         if (searchParams?.sale_date) {
  //             sales?.push("Sales Date")
  //         }
  //         if (searchParams?.sale_date) {
  //             sales?.push("Sales Date")
  //         }
  //         if (searchParams?.min_sales_price) {
  //             sales?.push("Sales Price")
  //         }
  //         if (searchParams?.max_sales_price) {
  //             sales?.push("Sales Price")
  //         }
  //         if (searchParams?.mortgage_amount) {
  //             setValue("debt", "Mortgage Amount")
  //         }
  //         if (searchParams?.forclosure_properties) {
  //             setValue("distressed", "Foreclosed")
  //         }
  //         if (searchParams?.tax_min_year) {
  //             tax?.push("Tax year")
  //         }
  //         if (searchParams?.tax_max_year) {
  //             tax?.push("Tax year")
  //         }
  //         if (searchParams?.min_tax_amount) {
  //             tax?.push("Tax Amount")
  //         }
  //         if (searchParams?.max_tax_amount) {
  //             tax?.push("Tax Amount")
  //         }
  //     }
  //     setValue('owner_contact_info', owner_contact_info)
  //     setValue('building_lot', building_lot)
  //     setValue('sales', sales)
  // }, []);

  const onSubmit = (data, e) => {
    if (
      data?.start === undefined ||
      data?.start === null ||
      data?.start === ""
    ) {
      data = { ...data, start: null };
    }
    if (data?.end === undefined || data?.end === null || data?.end === "") {
      data = { ...data, end: null };
    }

    e.preventDefault();
    handleExportPdf(data);
  };

  const goBack = () => {
    window.history.back();
  };

  const changeTabType = (e, type) => {
    e.preventDefault();
    setTabType(type);
  };

  const isChecked = (field, value) =>
    Array.isArray(field) && field.includes(value);
  const propertyTypes = [
    "Agricultural",
    "Commercial",
    "Exempt",
    "Industrial",
    "Miscellaneous",
    "Office",
    "Recreational",
    "Residential",
    "Vacant Land",
  ];
  const chunkSize = Math.ceil(propertyTypes.length / 4);
  const columns = [
    propertyTypes.slice(0, chunkSize),
    propertyTypes.slice(chunkSize, chunkSize * 2),
    propertyTypes.slice(chunkSize * 2, chunkSize * 3),
    propertyTypes.slice(chunkSize * 3),
  ];
  return (
    <>
      <div className="top_strip_export">
        <div className="contain-fluid">
          <div className="flex">
            <Link to="#!" onClick={goBack}>
              <img src="/images/back_arrow.svg" alt="" />
              <span>Export</span>
            </Link>
            <div className="label_info">
              <small>
                {total_available_credits ? total_available_credits : 0} export
                credits available
              </small>
              <div className="pro_ress"></div>
            </div>
          </div>
        </div>
      </div>
      {properties?.length > 0 || searched_properties?.length > 0 ? (
        <section className="export_sec">
          <div className="contain-fluid">
            <form method="POST" onSubmit={handleSubmit(onSubmit)}>
              <div className="row form_row field_row cell_form_row">
                <div className="col-md-5">
                  <div className="form_blk">
                    <h6 className="lbl_heading">Name</h6>
                    <input
                      type="text"
                      className="input"
                      {...register("name", { required: "Required." })}
                    />
                    <span className="validation-error">
                      {errors?.name?.message}
                    </span>
                  </div>
                </div>
                <div className="col-md-3">
                  {properties?.length > 0 ? (
                    <div className="form_blk">
                      <h6 className="lbl_heading">Properties to Export</h6>
                      <input
                        type="number"
                        className="input"
                        value={properties?.length}
                        readOnly
                      />
                    </div>
                  ) : (
                    <div className="form_blk">
                      <h6 className="lbl_heading">
                        Total Properties to Export
                      </h6>
                      <input
                        type="number"
                        className="input"
                        value={searched_properties?.length}
                        readOnly
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="br"></div>
              <div className="file_output_row">
                <h6 className="lbl_heading">File Output</h6>
                <div className="btn_lbl_blk">
                  <button
                    type="button"
                    className={
                      watchAllFields?.file_type === "excel"
                        ? "btn_lbl active"
                        : "btn_lbl"
                    }
                    onClick={() => setValue("file_type", "excel")}
                  >
                    Excel (XLSX)
                  </button>
                  <button
                    type="button"
                    className={
                      watchAllFields?.file_type === "csv"
                        ? "btn_lbl active"
                        : "btn_lbl"
                    }
                    onClick={() => setValue("file_type", "csv")}
                  >
                    CSV
                  </button>
                </div>
              </div>
              <div className="br"></div>
              {properties?.length > 0 ? (
                <div className="preview_blk">
                  <h6 className="lbl_heading">Preview</h6>
                  <div className="outer_table">
                    <table>
                      <thead>
                        <tr>
                          <th>Parcel ID</th>
                          <th>Address</th>
                          <th>Link</th>
                        </tr>
                      </thead>
                      <tbody>
                        {properties?.map((property, index) => (
                          <tr key={index}>
                            <td>{property?.parcel_id}</td>
                            <td>{property?.address}</td>
                            <td>
                              <Link
                                to={`/property-details/${property.id}`}
                                target="_blank"
                              >
                                Click here to view
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className="row form_row field_row cell_form_row">
                  <div className="col-md-12">
                    <h6 className="lbl_heading">
                      Please specify a start number and an end number to
                      download the properties within that range.{" "}
                    </h6>
                  </div>
                  <div className="col-md-6">
                    <div className="form_blk">
                      <h6 className="lbl_heading">Start</h6>
                      <input
                        type="number"
                        className="input"
                        {...register("start", {
                          required: "Required.",
                          validate: (value) => {
                            const endValue = watch("end");
                            return (
                              value < endValue || "Start must be less than End"
                            );
                          },
                        })}
                      />
                      <span className="validation-error">
                        {errors?.start?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form_blk">
                      <h6 className="lbl_heading">End</h6>
                      <input
                        type="number"
                        className="input"
                        {...register("end", {
                          required: "Required.",
                          validate: (value) => {
                            const startValue = watch("start");
                            const total =
                              parseInt(startValue) && parseInt(value)
                                ? parseInt(value) - parseInt(startValue)
                                : 0;
                            if (
                              parseInt(startValue) &&
                              parseInt(value) <= parseInt(startValue)
                            ) {
                              return "End must be greater than Start";
                            }
                            if (total > 400) {
                              return `Total range should not exceed 400. Current total: ${total}`;
                            }
                            if (
                              parseInt(startValue) &&
                              parseInt(startValue) >= 400 &&
                              parseInt(value) > parseInt(startValue) + 400
                            ) {
                              return `End should not be greater than ${
                                parseInt(startValue) + 400
                              }`;
                            }
                            return true;
                          },
                        })}
                      />
                      <span className="validation-error">
                        {errors?.end?.message}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className="br"></div>
              <div className="btn_blk btn_lst_export">
                {[
                  "property_types",
                  "building_lot",
                  "sales",
                  "debt",
                  "distressed",
                  "tax",
                  "my_properties",
                  "owner_contact_info",
                ].map((type) => (
                  <button
                    key={type}
                    type="button"
                    className={tabType === type ? "site_btn" : "site_btn blank"}
                    onClick={(e) => changeTabType(e, type)}
                  >
                    {type
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </button>
                ))}
              </div>
              <div className="br"></div>
              {tabType === "property_types" && (
                <div className="property_fields">
                  <div className="head_field">
                    <h6>Property fields</h6>
                  </div>
                  <div className="check_flex">
                    {columns.map((column, index) => (
                      <div key={index} className="check_inner">
                        {column.map((type) => (
                          <div key={type} className="lbl_btn">
                            <input
                              type="checkbox"
                              value={type}
                              id={type}
                              {...register("property_type")}
                              defaultChecked={isChecked(
                                watchAllFields.property_type,
                                type
                              )}
                            />
                            <label htmlFor={type}>{type}</label>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {tabType === "building_lot" && (
                <div className="property_fields">
                  <div className="head_field">
                    <h6>Building & Lot</h6>
                  </div>
                  <div className="check_flex">
                    <div className="check_inner">
                      {["Year built", "Lot Size", "Zoning"].map((type) => (
                        <div key={type} className="lbl_btn">
                          <input
                            type="checkbox"
                            value={type}
                            id={type}
                            {...register("building_lot")}
                            defaultChecked={isChecked(
                              watchAllFields.building_lot,
                              type
                            )}
                          />
                          <label htmlFor={type}>{type}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {tabType === "sales" && (
                <div className="property_fields">
                  <div className="head_field">
                    <h6>Sales</h6>
                  </div>
                  <div className="check_flex">
                    <div className="check_inner">
                      {["Sales Date", "Sales Price"].map((type) => (
                        <div key={type} className="lbl_btn">
                          <input
                            type="checkbox"
                            value={type}
                            id={type}
                            {...register("sales")}
                            defaultChecked={isChecked(
                              watchAllFields.sales,
                              type
                            )}
                          />
                          <label htmlFor={type}>{type}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {tabType === "debt" && (
                <div className="property_fields">
                  <div className="head_field">
                    <h6>Debt</h6>
                  </div>
                  <div className="check_flex">
                    <div className="check_inner">
                      {["Mortgage Amount"].map((type) => (
                        <div key={type} className="lbl_btn">
                          <input
                            type="checkbox"
                            value={type}
                            id={type}
                            {...register("debt")}
                            defaultChecked={isChecked(
                              watchAllFields.debt,
                              type
                            )}
                          />
                          <label htmlFor={type}>{type}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {tabType === "distressed" && (
                <div className="property_fields">
                  <div className="head_field">
                    <h6>Distressed</h6>
                  </div>
                  <div className="check_flex">
                    <div className="check_inner">
                      {["Foreclosed"].map((type) => (
                        <div key={type} className="lbl_btn">
                          <input
                            type="checkbox"
                            value={type}
                            id={type}
                            {...register("distressed")}
                            defaultChecked={isChecked(
                              watchAllFields.distressed,
                              type
                            )}
                          />
                          <label htmlFor={type}>{type}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {tabType === "tax" && (
                <div className="property_fields">
                  <div className="head_field">
                    <h6>Tax</h6>
                  </div>
                  <div className="check_flex">
                    <div className="check_inner">
                      {["Tax year", "Tax Amount"].map((type) => (
                        <div key={type} className="lbl_btn">
                          <input
                            type="checkbox"
                            value={type}
                            id={type}
                            {...register("tax")}
                            defaultChecked={isChecked(watchAllFields.tax, type)}
                          />
                          <label htmlFor={type}>{type}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {labels?.length > 0 && tabType === "my_properties" && (
                <div className="property_fields">
                  <div className="head_field">
                    <h6>My Properties</h6>
                  </div>
                  <div className="check_flex">
                    <div className="check_inner">
                      {labels?.map((label) => (
                        <div key={label?.label_name} className="lbl_btn">
                          <input
                            type="checkbox"
                            value={label?.label_name}
                            id={label?.label_name}
                            {...register("labels")}
                            defaultChecked={isChecked(
                              watchAllFields.my_properties,
                              label?.id
                            )}
                          />
                          <label htmlFor={label?.label_name}>
                            {label?.label_name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {tabType === "owner_contact_info" && (
                <div className="property_fields">
                  <div className="head_field">
                    <h6>Owner Contact Info</h6>
                  </div>
                  <div className="check_flex">
                    <div className="check_inner">
                      {[
                        "Owner Name",
                        "Owner Address",
                        "Contact Phone",
                        "Contact Email",
                        "Contact Mailing Address",
                      ].map((type) => (
                        <div key={type} className="lbl_btn">
                          <input
                            type="checkbox"
                            value={type}
                            id={type}
                            {...register("owner_contact_info")}
                            defaultChecked={isChecked(
                              watchAllFields.owner_contact_info,
                              type
                            )}
                          />
                          <label htmlFor={type}>{type}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <div className="btn_blk">
                <button
                  className="site_btn"
                  type="submit"
                  disabled={isFormProcessing}
                >
                  Export {isFormProcessing && <i className="spinner"></i>}
                </button>
              </div>
            </form>
          </div>
        </section>
      ) : (
        <section className="export_sec">
          <div className="contain-fluid">
            <div className="alert alert-danger">
              Please select some properties from search page. No properties to
              export!
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ExportInfo;
