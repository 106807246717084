import React,{useState} from "react";
import { Link } from "react-router-dom";
import http from "../../../helpers/http";
import { doObjToFormData } from "../../../helpers/helpers";
import { updateMEmberCredits } from "../../../states/actions/fetchSiteSettings";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import Text from "../../common/Text";

export default function PropertyTableViewBlk({val,handleCheckboxChange,displayedColumns,handleToggleForLabel,handleLikeClick,i}) {
    const [isTypeLoading, setIsTypeLoading] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(null);
const dispatch=useDispatch()
  const handleRequestMailOrTel = (e, type) => {
    e.preventDefault();
    setIsTypeLoading(true);
    http
      .post(
        `request-property-contact/${val?.id}`,
        doObjToFormData({ type: type })
      )
      .then(({ data }) => {
        setIsTypeLoading(false);
        if (data?.status === 1) {
          dispatch(updateMEmberCredits(data?.total_available_credits));
          setShowContactInfo({
            email: data?.email,
            phone: data?.phone,
          });
        } else {
          toast.error(<Text string={data?.msg} />, TOAST_SETTINGS);
        }
      })
      .catch((error) => {
        setIsTypeLoading(false);
      });
  };
  return <>
  <div className="flex" key={i + 98723456 + 12789100000000}>
                    <div className="col_all col_1">
                      <div className="flex">
                        {/* <div className="sm_icon"> */}
                        <input
                          type="checkbox"
                          name="owners[]"
                          checked={val?.checked}
                          onChange={(e) => handleCheckboxChange(e, i)}
                        />
                        {/* <img src="/images/chart-tree.svg" alt="" /> */}
                        {/* </div> */}
                        <div className="_cntnt">
                          <h4>
                            <Link
                              to={`/property-details/${val.id}`}
                              target="_blank"
                            >
                              {val.title}
                            </Link>
                          </h4>
                        </div>
                      </div>
                    </div>
                    {displayedColumns?.includes('date') && (
                      <div className="col_all">
                        <small>Last Sale</small>
                        <p>{val.date}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('price') && (
                      <div className="col_all">
                        <small>Sales Price</small>
                        <p>${Number(val.price).toLocaleString()}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('build') && (
                      <div className="col_all">
                        <small>Year Built</small>
                        <p>{val.build}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('square') && (
                      <div className="col_all">
                        <small>Building Area</small>
                        <p>{Number(val.square).toLocaleString()}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('property_type') && (
                      <div className="col_all">
                        <small>Property Type</small>
                        <p>{val.property_type}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('zonedcodelocal') && (
                      <div className="col_all">
                        <small>Zoning</small>
                        <p>{val.zonedcodelocal}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('mortgage1amount') && (
                      <div className="col_all">
                        <small>Mortgage Amount</small>
                        <p>{val.mortgage1amount}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('foreclosureauctionsale') && (
                      <div className="col_all">
                        <small>Foreclosed</small>
                        <p>{val.foreclosureauctionsale}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('taxyearassessed') && (
                      <div className="col_all">
                        <small>Tax Year</small>
                        <p>{val.taxyearassessed}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('taxassessedvaluetotal') && (
                      <div className="col_all">
                        <small>Tax Price</small>
                        <p>${Number(val.taxassessedvaluetotal).toLocaleString()}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('owner') && (
                      <div className="col_all">
                        <small>Reported Owner</small>
                        <p>{val.owner}</p>
                      </div>
                    )}
                    {displayedColumns?.includes('email') || displayedColumns?.includes('call') ? (
                      <div className="col_all">
                        <small>Contact</small>
                        <div className="contact_links">
                        {showContactInfo !== null ? (
                            <>
                                {showContactInfo?.email && (
                                    <a href={"mailto:" + showContactInfo.email}>
                                        <img src="/images/email-icon.svg" alt="" />
                                    </a>
                                )}
                                {showContactInfo?.phone && (
                                    <a href={"tel:" + showContactInfo?.phone}>
                                        <img src="/images/call-icon.svg" alt="" />
                                    </a>
                                )}
                            </>
                         ) : (
                            <div className="contact_info relative">
                                <div className="dp">
                                <img src="/images/profile.svg" alt="" />
                                </div>
                                <div className="cnt">
                                <h5>
                                    <a
                                    href="#!"
                                    onClick={(e) => handleRequestMailOrTel(e, "call")}
                                    disabled={isTypeLoading}
                                    >
                                    <div className="p_contact_wrap">
                                        {isTypeLoading
                                        ? "Please wait!"
                                        : "View Contact"}
                                    </div>
                                    </a>
                                </h5>
                                </div>
                                {isTypeLoading ? (
                                <div class="loadingio-spinner-eclipse-spin">
                                    <div class="load-spinner">
                                    <div></div>
                                    </div>
                                </div>
                                ) : (
                                ""
                                )}
                            </div>
                            )}
                          
                        </div>
                      </div>
                    ) : null}
                    <div className="col_all">
                      <small>Actions</small>
                      <div className="cta_opt_lbl">
                        <a
                          href="javascript:void(0)"
                          onClick={() => handleToggleForLabel(val.id)}
                        >
                          <img src="/images/ticket_star.svg" alt="" />
                        </a>
                        <Link
                          to={`/property-details/${val.id}`}
                          target="_blank"
                        >
                          <img src="/images/maximize.svg" alt="" />
                        </Link>
                        <a
                          href="javascript:void(0)"
                          style={
                            val.is_wish == 1 ? { background: "#67773f" } : {}
                          }
                          onClick={
                            val.is_wish
                              ? null
                              : () => handleLikeClick(val.id, dispatch)
                          }
                        >
                          <img
                            style={
                              val.is_wish == 1
                                ? { filter: "brightness(0) invert(1)" }
                                : {}
                            }
                            src="/images/heart_ico.svg"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
  </>;
}
