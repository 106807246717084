import React, { useEffect, useState } from "react";
import SubmitButton from "../../common/SubmitButton";
import { useForm } from "react-hook-form";
import http from "../../../helpers/http";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import { toast } from "react-toastify";
import Text from "../../common/Text";
import { getWordInitials } from "../../../helpers/helpers";
import ImageControl from "../../common/ImageControl";

export default function PrtopertyDetailsNotes({
  property_id,
  user_notes,
  propertyNotes,
  setPropertyNotes,
}) {
  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    register,
    reset,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (frmData) => {
    setIsFormProcessing(true);
    http
      .post("/addNotesToProperty/" + property_id, frmData)
      .then((response) => {
        setIsFormProcessing(false);
        if (response?.data?.status) {
          toast.success(response.data.msg, TOAST_SETTINGS);
          reset();
          if (response?.data?.notes_arr?.length > 0) {
            setPropertyNotes(response?.data?.notes_arr);
          }
        } else {
          toast.error(response.data.msg, TOAST_SETTINGS);
        }
      })
      .catch((error) => {
        setIsFormProcessing(false);
        toast.error(<Text string="wishlist.error" />, TOAST_SETTINGS);
      });
  };
  useEffect(() => {
    if (user_notes) {
      setValue("notes", user_notes);
    }
  }, [user_notes]);
  const handleDeleteNots = (e, note_id) => {
    e.preventDefault();
    if (note_id > 0) {
      setIsDeleting(true);
      http
        .post("/deleteUserNote/" + note_id, { property_id: property_id })
        .then((response) => {
          setIsDeleting(false);
          if (response?.data?.status) {
            toast.success(response.data.msg, TOAST_SETTINGS);
            // if (response?.data?.notes_arr?.length > 0) {
            setPropertyNotes(response?.data?.notes_arr);
            // }
          } else {
            toast.error(response.data.msg, TOAST_SETTINGS);
          }
        })
        .catch((error) => {
          setIsDeleting(false);
          toast.error(<Text string="delete-note-error" />, TOAST_SETTINGS);
        });
    } else {
      toast.error(<Text string="Invalid delete request" />, TOAST_SETTINGS);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form_blk">
          <textarea
            id="frm-notes"
            name="notes"
            className="input"
            placeholder="Enter Your notes Here"
            {...register("notes", {
              required: "notes is required.",
            })}
          ></textarea>
          <span className="validation-error">{errors.message?.notes}</span>
        </div>
        <div className="btn_blk">
          <SubmitButton
            classes="site_btn"
            button_text={"Send"}
            isFormProcessing={isFormProcessing}
          />
        </div>
      </form>
      <hr />
      {propertyNotes?.length > 0 ? (
        <div className="profile_show_pg relative">
          <div class="tile_blk">
            <div class="review_blk">
              {isDeleting ? (
                <div className="note_loading_wrapper">
                  <div class="loadingio-spinner">
                    <div class="ldio-loadr">
                      <div></div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {propertyNotes?.map((propertyNote, index) => {
                return (
                  <div class="inner_blk" key={index}>
                    <button
                      className="crosBtn"
                      type="button"
                      onClick={(e) => handleDeleteNots(e, propertyNote?.id)}
                    >
                      x
                    </button>
                    <div class="review_header">
                      <div class="review_person">
                        <div className="profile_btn drop_btn">
                          {getWordInitials(`${propertyNote?.user_name}`)}
                        </div>
                        <div class="user_info">
                          <h6>{propertyNote?.user_name}</h6>
                          {/* <span>{propertyNote?.created_date}</span> */}
                        </div>
                      </div>
                    </div>
                    <div class="cmnt_pera">
                      <p>{propertyNote?.notes}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
