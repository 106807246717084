import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getSearchTag, timeAgo } from "../../../helpers/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSearchHistory,
  updateSearchHistory,
} from "../../../states/actions/fetchSavedSearches";
import SubmitButton from "../../common/SubmitButton";
import { ToastContainer, toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";

const Searches = ({ lastestSearchTime, handleChangeSort }) => {
  const [searchSort, setSearchSort] = useState(null);
  const [editPopup, setEditPopup] = useState({ item: null, show: false });
  const dispatch = useDispatch();
  const isProcessing = useSelector(
    (state) => state.fetchSavedSearches.isProcessing
  );
  const searches = useSelector((state) => state.fetchSavedSearches.searches);
  const sort = useSelector((state) => state.fetchSavedSearches.sort);
  useEffect(() => {
    if (sort) {
      setSearchSort(sort);
    }
  }, [sort]);

  const handleDeleteSearchHistory = (e, search_id) => {
    e.preventDefault();
    dispatch(deleteSearchHistory(search_id));
  };
  const navigate = useNavigate();
  const redirectToSearchPage = (data) => {
    let url = `/search-property?${objectToQueryString(data)}`;
    window.location.href = url;
    // navigate(url);
  };
  const objectToQueryString = (obj) => {
    const keyValuePairs = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        keyValuePairs.push(
          encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
        );
      }
    }
    return keyValuePairs.join("&");
  };
  const [editLabel, setEditLAbel] = useState(null);
  const handleChangeEditLabel = (e, item) => {
    setEditLAbel(e.target.value);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (editLabel === "" || editLabel === null || editLabel === undefined) {
      toast.error("Keyword is required!", TOAST_SETTINGS);
      return;
    } else {
      const newData = { keyword: editLabel, search_id: editPopup?.item?.id };
      dispatch(updateSearchHistory(newData));
    }
  };
  const editSearch = (e, item) => {
    e.preventDefault();
    setEditPopup({ show: true, item: item });
  };
  useEffect(() => {
    if (editPopup?.item) {
      setEditLAbel(editPopup?.item?.keyword);
    }
  }, [editPopup?.item]);

  const onChangeSort = (e) => {
    setSearchSort(e.target.value);
    const newData = { sort: e.target.value };
    handleChangeSort(newData);
  };
  return (
    <>
      <section className="searches_saved_sec">
        <ToastContainer />
        <div className="contain-fluid">
          <div className="searches_blk">
            <div className="flex searchFlex">
              <h4>Recent Saved Searches</h4>
              <div className="flex justify-content-between flex-column">
                <label>Sort By:</label>
                <select name="sort" className="input" onChange={onChangeSort}>
                  <option value={""}>Select</option>
                  <option
                    value={"asc"}
                    selected={searchSort == "asc" ? true : false}
                  >
                    A-Z
                  </option>
                  <option
                    value={"desc"}
                    selected={searchSort == "desc" ? true : false}
                  >
                    Z-A
                  </option>
                  <option
                    value={"date_old"}
                    selected={searchSort == "date_old" ? true : false}
                  >
                    Date created: Oldest
                  </option>
                  <option
                    value={"date_new"}
                    selected={searchSort == "date_new" ? true : false}
                  >
                    Date created: newest
                  </option>
                </select>
              </div>
            </div>

            <div className="flex search_flex">
              {isProcessing ? (
                <div className="loadr">
                  <img src="/images/loading.gif" />
                </div>
              ) : (
                ""
              )}
              {searches && searches.length > 0
                ? searches.map((search, i) => (
                    <>
                      <div className="relative col">
                        <div key={i + 438912789100000000} className="blk">
                          {/* {getSearchTag(type, data)} */}
                          <h4>
                            <a
                              href="javascript:void(0)"
                              onClick={() =>
                                redirectToSearchPage(search?.query)
                              }
                            >
                              {search?.keyword + " On " + search?.searched_date}
                            </a>
                          </h4>
                          <div className="flex serchBtnBlk">
                            <div
                              className="srch_icon"
                              onClick={(e) => editSearch(e, search)}
                            >
                              <img src="/images/pencil.svg" />
                            </div>
                            <div
                              className="srch_icon"
                              onClick={(e) =>
                                handleDeleteSearchHistory(e, search?.id)
                              }
                            >
                              <img src="/images/delete.svg" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))
                : "The searches that you have made will show here"}
            </div>
            {/* {searches.length > 0 && (
              <div className="time">{timeAgo(lastestSearchTime)}</div>
            )} */}
            {/* {searches.length > 0 && (
              <div className="act_btn">
                <button
                  type="button"
                  onClick={handleDeleteSearchHistory}
                  className="delete crosBtn"
                  disabled={isProcessing}
                >
                  <img src="/images/delete.svg" alt="" />
                </button>
              </div>
            )} */}
          </div>
          <div className="devide_line"></div>
          <div className="searches_blk" hidden>
            <h4>Renovation leads</h4>
            <div className="flex">
              <button>Built upto 2022</button>
              <button>Property type: Multifamily</button>
              <button>Sales: Post 2 years</button>
              <button>Property type: Multifamily</button>
            </div>
            <div className="time">A week ago</div>
            <div className="devide_line"></div>
            <div className="act_btn">
              <Link to="/" className="edit">
                <img src="/images/edit-icon.svg" alt="" />
              </Link>
              <Link to="/" className="delete">
                <img src="/images/delete.svg" alt="" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      {editPopup?.show === true &&
      editPopup?.item !== null &&
      editPopup?.item?.id > 0 ? (
        <div class="popup popup_password_change active">
          <div className="table_dv">
            <div className="table_cell">
              <div className="contain">
                <div className="_inner">
                  <button
                    type="button"
                    className="x_btn"
                    onClick={() => setEditPopup({ show: false, item: null })}
                  ></button>
                  <h6>
                    Update <strong>{editPopup?.item?.keyword}</strong>
                  </h6>
                  <form onSubmit={onSubmit}>
                    <div className="blk_form">
                      <h6>Search Title</h6>
                      <input
                        type="text"
                        className="input"
                        placeholder="Searched Title"
                        defaultValue={editLabel}
                        onChange={handleChangeEditLabel}
                      />
                    </div>
                    <div className="btn_blk text-center">
                      <SubmitButton
                        classes="site_btn"
                        button_text="Change"
                        isFormProcessing={isProcessing}
                      />
                      <button
                        className="site_btn blank red_blank"
                        type="button"
                        onClick={() =>
                          setEditPopup({ show: false, item: null })
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Searches;
