import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import UserInfoDropdown from "./UserInfoDropdown";
import ImageControl from "../common/ImageControl";
import useWindowSize from "../../hooks/useWindowSize";

export default function NavCell({ active, setToggle, member, industries_arr }) {
  const [resources, setResources] = useState(false);
  const [industries, setIndustries] = useState(false);
  const authToken = useSelector((state) => state.fetchSignin.authToken);
  const ToggleResources = (e) => {
    setResources(!resources);
    e.preventDefault();
  };
  const ToggleIndustries = () => {
    setIndustries(!industries);
  };

  const handleLinkClick = () => {
    setToggle(false);
    setIndustries(false);
    setResources(false);
  };

  const { width } = useWindowSize();
  const total_available_credits = useSelector(
    (state) => state.fetchSiteSettings.total_available_credits
  );
  const isTabletOrMobile = width <= 1024 && width >= 320;

  return (
    <div className="hide_ds_screen">
      <nav className={active ? "active" : ""} nav="true" id="nav">
        <ul className="nav_neg">
          <li className="">
            <NavLink
              to="/pricing"
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={handleLinkClick}
            >
              Pricing
            </NavLink>
          </li>
          <li className="drop">
            <NavLink
              to="#"
              onClick={ToggleResources}
              className={({ isActive }) => (isActive ? "" : "")}
            >
              Resources
            </NavLink>
            <div className={resources ? "sub active" : "sub"}>
              <div className="flex_row row">
                <div className="col-md-12 resource_small_flex_tite">
                  <Link to="#" onClick={ToggleResources}>
                    <img src="/images/back_angle.svg" alt="" />
                    <span>Resources</span>
                  </Link>
                </div>
                <div className="col-md-6">
                  <div>
                    <div className="nav_sub_blk">
                      <div className="ico">
                        <img src="/images/blog_icon.svg" alt="" />
                      </div>
                      <div className="txt">
                        <Link to="/blogs" onClick={handleLinkClick}>
                          <h4 className="blue_color">
                            Blog <img src="/images/blog_arrow.svg" alt="" />
                          </h4>
                        </Link>
                        <p>
                          Our latest news, Featured Articles, Insights and how
                          to guided for motivated and curious professional
                        </p>
                        <Link to="/blogs" onClick={handleLinkClick}>
                          <span>Visit Blog</span>
                          <img src="/images/arrow_mini.svg" alt="" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <div className="nav_sub_blk">
                      <div className="ico">
                        <img src="/images/help_icon.svg" alt="" />
                      </div>
                      <div className="txt">
                        <Link to="/help" onClick={handleLinkClick}>
                          <h4 className="green_color">
                            Help center{" "}
                            <img src="/images/help_arrow.svg" alt="" />
                          </h4>
                        </Link>
                        <p>
                          Our latest news, Featured Articles, Insights and how
                          to guided for motivated and curious professional
                        </p>
                        <Link to="/help" onClick={handleLinkClick}>
                          <span>Visit Help Center</span>
                          <img src="/images/arrow_mini.svg" alt="" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <div className="nav_sub_blk">
                      <div className="ico">
                        <img src="/images/support_icon.svg" alt="" />
                      </div>
                      <div className="txt">
                        <Link to="/support" onClick={handleLinkClick}>
                          <h4 className="zink_color">
                            Support{" "}
                            <img src="/images/support_arrow.svg" alt="" />
                          </h4>
                        </Link>
                        <p>
                          Our latest news, Featured Articles, Insights and how
                          to guided for motivated and curious professional
                        </p>
                        <Link to="/support" onClick={handleLinkClick}>
                          <span>Visit Support</span>
                          <img src="/images/arrow_mini.svg" alt="" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          {/* <li className="">
            <NavLink
              to="/about"
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={handleLinkClick}
            >
              About
            </NavLink>
          </li> */}

          <li className="drop">
            <NavLink
              to={isTabletOrMobile ? " " : "/industries"}
              onClick={ToggleIndustries}
              className={({ isActive }) => (isActive ? "" : "")}
            >
              Industries
            </NavLink>
            <div className={industries ? "sub sm active" : "sub sm"}>
              <div className="col-md-12 resource_small_flex_tite">
                <NavLink to="#" onClick={ToggleIndustries}>
                  <img src="/images/back_angle.svg" alt="" />
                  <span>Industries</span>
                </NavLink>
              </div>
              <ul>
                {industries_arr?.map((industry, index) => {
                  return (
                    <li key={index}>
                      <a
                        href={"/industry/" + industry?.slug}
                        className={({ isActive }) => (isActive ? "active" : "")}
                        onClick={handleLinkClick}
                      >
                        <ImageControl
                          folder="industries"
                          src={industry?.icon}
                        />
                        <span>{industry?.title}</span>
                      </a>
                    </li>
                  );
                })}
              </ul>

              <div className="view_cell_ipad">
                <Link to="/industries" onClick={handleLinkClick}>
                  <span>View All Industries</span>
                </Link>
              </div>
            </div>
          </li>
        </ul>
      </nav>

      {authToken && member?.id > 0 ? (
        <ul className="log_btns cell_right_less">
          <li className="show_ipad_only">
            <NavLink
              to="/search-property"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Search
            </NavLink>
          </li>
          <li className="show_ipad_only">
            <NavLink
              to="/saved-searches"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              Saved searches
            </NavLink>
          </li>
          <li className="credit_nav hide_ipad_ipad">
            <span>
              Credits :
              <em>{total_available_credits ? total_available_credits : 0}</em>
            </span>
          </li>
          <UserInfoDropdown
            is_public={true}
            credit={true}
            total_available_credits={total_available_credits}
          />
        </ul>
      ) : (
        <ul className="log_btns">
          <li>
            <NavLink to="/login" className="site_btn blank">
              Login
            </NavLink>
          </li>
          {member !== undefined &&
          member !== null &&
          member !== "" &&
          member?.subscription === 1 ? (
            ""
          ) : (
            <li>
              <NavLink to="/signup" className="site_btn">
                Free Trial
              </NavLink>
            </li>
          )}
        </ul>
      )}
      {/* {member !== undefined &&
          member !== null &&
          member !== "" &&
          member?.subscription === 1 ? (
          ""
        ) : (
          <ul className="log_btns">
          <li>
            <NavLink to="/signup" className="site_btn">
              Free Trial
            </NavLink>
          </li>
          </ul>
        )} */}
      <div className="clearfix"></div>
    </div>
  );
}
