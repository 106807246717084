import React, { useState, useEffect } from "react";
import Strip from "./Strip";
import SideBar from "./Side-bar";
import Profile from "./Profile";
import MyAccount from "./My-account";
import Notifications from "./Notifications";
import Exports from "./Exports";
import Billing from "./Billing";
import MembershipManagement from "./Membership-Management";

import {
  fetchProfileSettings,
  cancelSubscription,
} from "../../../states/actions/fetchProfileSettings";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { ToastContainer } from "react-toastify";
import Users from "./Users";

const AccountSettings = ({ member, site_settings }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(
    localStorage.getItem("accout-settings-active-tab")
      ? localStorage.getItem("accout-settings-active-tab")
      : "Profile"
  );
  const [mobActive, setMobActive] = useState(false);

  const isLoading = useSelector(
    (state) => state.fetchProfileSettings.isLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchPricing.isFormProcessing
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );

  useEffect(() => {
    dispatch(fetchProfileSettings());
  }, []);

  useDocumentTitle("Profile Settings - Rhinorecon");

  const togglePage = (pagename) => {
    localStorage.setItem("accout-settings-active-tab", pagename);
    setPage(pagename);
    setMobActive(!mobActive);
  };
  const ApplyActive = () => {
    setMobActive(!mobActive);
  };
  const handleCancelSubscription = (data) => {
    dispatch(cancelSubscription(data));
  };
  const subscription = member?.mem_subscription;
  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <ToastContainer />
          <section className="account_settings">
            <Strip
              activePage={page}
              activeApply={ApplyActive}
              mobActive={mobActive}
            />
            <div className="contain-fluid">
              <div className="flex multi_components">
                <div className="colL">
                  <SideBar
                    pageToggle={togglePage}
                    activePage={page}
                    mobActive={mobActive}
                  />
                </div>
                <div className={mobActive ? "colR active" : "colR"}>
                  {page === "Profile" && <Profile />}
                  {page === "MyAccount" && <MyAccount />}
                  {page === "Notifications" && <Notifications />}
                  {page === "Exports" && <Exports />}
                  {page === "Billing" && <Billing member={member} />}
                  {page === "MembershipManagement" && (
                    <MembershipManagement
                      subscription={subscription}
                      site_settings={site_settings}
                      member={member}
                      handleCancelSubscription={handleCancelSubscription}
                      isFormProcessing={isFormProcessing}
                    />
                  )}
                  {page === "Users" && <Users />}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default AccountSettings;
