import React from "react";
import { Link } from "react-router-dom";
import ImageControl from "../../common/ImageControl";
import Text from "../../common/Text";

const SocialInfo = ({ content }) => {
  return (
    <>
      <section className="social_info_sec">
        <div className="contain">
          <div className="flex">
            <div className="col" key={1000}>
              <div className="inner">
                <div className="sm_icon">
                  <ImageControl src={content.image1} folder="images" />
                </div>
                <div className="cnt">
                  <h5>
                    <Text string={content.section2_cards_heading_1} />
                  </h5>
                  <p>
                    <Text string={content.section2_cards_text_1} />
                  </p>
                  <a href={content.section2_cards_link_1} target="_blank">
                    <span>
                      <Text string={content.section2_cards_link_text_1} />
                    </span>
                    <img src="/images/arrow_mini.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col" key={2000}>
              <div className="inner">
                <div className="sm_icon">
                  <ImageControl src={content.image2} folder="images" />
                </div>
                <div className="cnt">
                  <h5>
                    <Text string={content.section2_cards_heading_2} />
                  </h5>
                  <p>
                    <Text string={content.section2_cards_text_2} />
                  </p>
                  <a href={content.section2_cards_link_2} target="_blank">
                    <span>
                      <Text
                        string={content.section2_cards_link_text_2}
                        target="_blank"
                      />
                    </span>
                    <img src="/images/arrow_mini.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col" key={3000}>
              <div className="inner">
                <div className="sm_icon">
                  <ImageControl src={content.image3} folder="images" />
                </div>
                <div className="cnt">
                  <h5>
                    <Text string={content.section2_cards_heading_3} />
                  </h5>
                  <p>
                    <Text string={content.section2_cards_text_3} />
                  </p>
                  <a
                    href={"mailto:" + content.section2_cards_link_3}
                    target="_blank"
                  >
                    <span>
                      <Text string={content.section2_cards_link_text_3} />
                    </span>
                    <img src="/images/arrow_mini.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col" key={4000}>
              <div className="inner">
                <div className="sm_icon">
                  <ImageControl src={content.image4} folder="images" />
                </div>
                <div className="cnt">
                  <h5>
                    <Text string={content.section2_cards_heading_4} />
                  </h5>
                  <p>
                    <Text string={content.section2_cards_text_4} />
                  </p>
                  <a href={"tel:" + content.section2_cards_link_4}>
                    <span>
                      <Text string={content.section2_cards_link_text_4} />
                    </span>
                    <img src="/images/arrow_mini.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SocialInfo;
