import React from "react";
import { useSelector } from "react-redux";
import { eventDateFormat } from "../../../helpers/helpers";
import { useState } from "react";
import http from "../../../helpers/http";
import { doObjToFormData } from "../../../helpers/helpers";
import { Link } from "react-router-dom";
import UpdateCardDetails from "./update-card-details";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const Billing = ({ member }) => {
  const stripe_default_payment_method = member?.stripe_default_payment_method;
  console.log(stripe_default_payment_method);
  const [isExporting, setIsExporting] = useState(false);
  const billingData = useSelector(
    (state) => state.fetchProfileSettings.billingData
  );

  const handleDownloadCsv = (id) => {
    setIsExporting(true);
    http
      .post(`export-billing-record`, doObjToFormData({ id }), {
        responseType: "blob",
      })
      .then(({ data }) => {
        const href = URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = href;
        // let filename = propertyData.address
        //   ? `${propertyData.address} details.pdf`
        //   : "property-details.pdf";
        let filename = "billing-details.pdf";
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        // const { exportRecord, filename } = data;
        // if (exportRecord.length > 0) {
        //   const columnNames = ["sr", ...Object.keys(exportRecord[0])];
        //   const csvContent =
        //     "data:text/csv;charset=utf-8," +
        //     [columnNames.map((column) => `"${column}"`).join(",")]
        //       .concat(
        //         exportRecord.map((item, index) =>
        //           [
        //             `"${index + 1}"`, // Index as column
        //             ...Object.values(item).map((value) => `"${value}"`) // Data as rows
        //           ].join(",")
        //         )
        //       )
        //       .join("\n");

        //   const encodedUri = encodeURI(csvContent);
        //   const link = document.createElement("a");
        //   link.setAttribute("href", encodedUri);
        //   link.setAttribute("download", `${filename}.csv`);
        //   document.body.appendChild(link);
        //   link.click();
        // }

        setIsExporting(false);
      })
      .catch((error) => {
        setIsExporting(false);
      });
  };
  const [cardPopup, setCardPopup] = useState(false);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  return (
    <>
      <div className="setting_blk wide_md cell_min_padding">
        <h4>Billing</h4>
        <div className="mini_color_text mini_color_text_semi hide_cell_text_acc">
          Card Information
        </div>
        <ul className="flex_ul hide_cell_text_acc">
          <li>
            <span>
              Credit card ending with{" "}
              {stripe_default_payment_method?.card_number}
            </span>{" "}
            <span>Expires: {stripe_default_payment_method?.card_exp_date}</span>
          </li>
          <li>
            <Link
              to="#!"
              className="mini_color_text"
              onClick={() => setCardPopup(true)}
            >
              Update Card info
            </Link>
          </li>
        </ul>
        <div className="table_blk">
          <div className="inner_tbl">
            <table className="cell_hide_dowload_lbl">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Statements ID</th>
                  <th>Amount</th>
                  <th className="mini_color_text">
                    <div className="mini_color_text">
                      Next Billing on: 28
                      <span className="cell_block">Sep, 2022</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {billingData && billingData.length > 0 ? (
                  billingData.map((row, i) => (
                    <tr>
                      <td>{eventDateFormat(row.created_date)}</td>
                      <td>sid00{row.id}</td>
                      <td>${row.amount}</td>
                      <td>
                        <div
                          className="down_load_lbl"
                          onClick={() => handleDownloadCsv(row.id)}
                          disabled={isExporting}
                        >
                          <span>Download</span>
                          <img src="/images/download_icon.svg" alt="" />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>The payment you have made will show here.</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {cardPopup ? (
        <Elements stripe={stripePromise}>
          <UpdateCardDetails
            setCardPopup={setCardPopup}
            card_details={stripe_default_payment_method}
          />
        </Elements>
      ) : (
        ""
      )}
    </>
  );
};

export default Billing;
