import bulletIcon from "../../../assets/images/bullet-icon.png";
import linkIcon from "../../../assets/images/link-icon.png";
import { useState, useEffect } from "react";
import http from "../../../helpers/http";
import { Link } from "react-router-dom";

const PopupContent = ({ feature }) => {
  const [encodedParcelNumber, setEncodedParcelNumber] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (feature) {
      const parcelnumb = feature.properties.parcelnumb;
      const parcel_formatted = parcelnumb.replace(/[^0-9]/g, "");

      http
        .post(
          "/createencodedparcelid",
          {
            parcel_id: parcel_formatted,
          },
          {}
        )
        .then((res) => {
          if (res.data.status === 0) {
            setEncodedParcelNumber(-1);
            return;
          }
          setEncodedParcelNumber(res.data.encoded_parcel_id);
        })
        .catch((err) => {
          console.log(err);
          setEncodedParcelNumber(-1);
        });
    }
  }, [feature]);

  console.log("features", feature);
  const { properties: data } = feature;

  return (
    <div>
      <p style={styles.title}>{data.address}</p>
      <div style={styles.row}>
        <img src={bulletIcon} alt="li" style={{ maxWidth: 16 }} />
        <p style={styles.info}> {data.ll_gisacre + " Acre " + data.usedesc}</p>
      </div>
      <div style={{ ...styles.row, marginBottom: 10 }}>
        <img src={bulletIcon} alt="li" style={{ maxWidth: 16 }} />
        <p style={styles.info}>Owner: {data.owner}</p>
      </div>

      <div style={styles.row}>
        <img src={linkIcon} alt="li" style={{ maxWidth: 16 }} />
        <p style={styles.link}>
          {encodedParcelNumber ? (
            encodedParcelNumber === -1 ? (
              "Link not found"
            ) : (
              <Link
                to={`/property-details/${encodedParcelNumber}/?previousUrl=${encodeURIComponent(
                  urlParams.toString()
                )}`}
              >
                View Property
              </Link>
            )
          ) : (
            "Generating Link..."
          )}
        </p>
      </div>
    </div>
  );
};

export default PopupContent;

const styles = {
  title: {
    fontWeight: 700,
    fontSize: 20,
    color: "#121900",
  },
  info: {
    fontWeight: 500,
    fontSize: 14,
    color: "#121900",
    opacity: 0.5,
    margin: 0,
  },
  row: {
    display: "flex",
    gap: 20,
    alignItems: "center",
  },

  link: {
    fontWeight: 700,
    fontSize: 15,
    color: "#121900",
  },
};
