import React, { useState, useEffect } from "react";
import SearchBanner from "./Search-banner";
import ViewdProperties from "./Viewd-properties";
import ViewdOwners from "./Viewd-owners";
import PropertyPost from "./Property-post";

import { fetchSignup } from "../../../states/actions/fetchSignup";
import { useSelector, useDispatch } from "react-redux";
import Text from "../../common/Text";
import { ToastContainer } from "react-toastify";

import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import Authentication from "../signup/Authentication";
import { NavLink } from "react-router-dom";
import http from "../../../helpers/http";
import * as helpers from "../../../helpers/helpers";
import {
  FETCH_PROPERTY_DETAIL_CONTENT,
  FETCH_PROPERTY_DETAIL_FAILED,
  FETCH_WISHLIST_CONTENT_FAILED,
  FETCH_WISHLIST_CONTENT_SUCCESS,
} from "../../../states/actions/actionTypes";

const fetchviewProperties = async (dispatch, setviewproperties) => {
  http
    .post("/getuserviewproperties")
    .then(({ data }) => {
      const property = data[0];

      setviewproperties(property);
      dispatch({
        type: FETCH_PROPERTY_DETAIL_CONTENT,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_PROPERTY_DETAIL_FAILED,
        payload: error,
      });
    });
};

const fetchviewOwners = async (dispatch, setviewowners) => {
  http
    .post("/getviewowners")
    .then(({ data }) => {
      const owners_data = data[0];

      setviewowners(owners_data);

      dispatch({
        type: FETCH_PROPERTY_DETAIL_CONTENT,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_PROPERTY_DETAIL_FAILED,
        payload: error,
      });
    });
};
const fetchPropertyPosts = async (dispatch, setpropertyposts) => {
  http
    .post("/getsimpleblogs")
    .then(({ data }) => {
      const property_data = data[0];

      setpropertyposts(property_data);

      dispatch({
        type: FETCH_PROPERTY_DETAIL_CONTENT,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_PROPERTY_DETAIL_FAILED,
        payload: error,
      });
    });
};

const PropertyListing = ({ member }) => {
  const dispatch = useDispatch();
  const [property, setviewproperties] = useState([]);
  const [owners, setviewowners] = useState([]);
  const [propertyposts, setpropertyposts] = useState([]);
  const data = useSelector((state) => state.fetchSignup.content);
  //const isLoading = useSelector((state) => state.fetchSignup.isLoading);
  const [isLoading, setLoading] = useState(true);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  useEffect(() => {
    dispatch(fetchSignup());
    fetchviewProperties(dispatch, setviewproperties);
    fetchviewOwners(dispatch, setviewowners);
    fetchPropertyPosts(dispatch, setpropertyposts);
  }, []);
  useEffect(() => {
    if (
      property.length === 0 ||
      propertyposts.length === 0 ||
      owners.length === 0
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [property, propertyposts, owners]);

  useDocumentTitle(
    member?.mem_verified === 0 ? "Email Authentication" : "Dashboard"
  );

  return (
    <>
      {isLoading || isSiteSettingsLoading ? (
        <LoadingScreen />
      ) : member !== null &&
        member !== undefined &&
        member?.mem_verified === 1 ? (
        member?.subscription === 1 ? (
          <>
            <SearchBanner />
            <ViewdProperties data={property} />
            <ViewdOwners data={owners} />
            <PropertyPost data={propertyposts} />
          </>
        ) : (
          <div id="dashboard_authenticate">
            <ToastContainer />
            <div className="contain">
              <div className="inner">
                <div className="content">
                  <h3>Your subscription is pending</h3>
                  <p>
                    You can not use dashboard pages until you subscribe to one
                    of plans.
                  </p>

                  <p>For Subscription go to: </p>
                  <div className="bTn">
                    <NavLink to="/pricing" className="lnk site_btn">
                      Pricing
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      ) : member?.id > 0 ? (
        <div id="dashboard_authenticate">
          <ToastContainer />
          <div className="contain">
            <div className="inner">
              <div className="content">
                <h3>Email Verification</h3>
                <p>
                  You are almost there! We sent an email to{" "}
                  <a href={"mailto:" + member?.mem_email} className="strong">
                    {member?.mem_email}
                  </a>
                </p>
                <p>
                  Just click on the link in that email to complete your sign-up.
                  If you don't see it, you may need to{" "}
                  <strong>check your spam</strong> folder.{" "}
                </p>
                <p>Still can't find the email?</p>
                <Authentication data={data} member={member} />
                <p>
                  Need Help?{" "}
                  <NavLink to="/contact" className="lnk">
                    Contact Us
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="alert alert-danger">Please login to continue!</div>
      )}
    </>
  );
};

export default PropertyListing;
