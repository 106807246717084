import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import bbox from '@turf/bbox';
import { centroid } from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Map, { Layer, NavigationControl, Popup, Source, GeolocateControl } from 'react-map-gl';
import wellknown, { parse } from 'wellknown';
import satelliteIcon from '../../../assets/images/satellite.png';
import streetsIcon from '../../../assets/images/streets.png';
import PopupContent from './Popup';

import axios from 'axios';

import DrawControl from './draw-control/DrawControl';
import styles from './Map.module.css';

mapboxgl.accessToken =
  'pk.eyJ1IjoibXRheXlhYm1pciIsImEiOiJjbDZuaWQ5Z3QwMTNyM2tsbGs2emtlc21uIn0.cwMj_2X3NoUdcbWnLGiG4g';

/* eslint-disable import/no-webpack-loader-syntax */
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const mapStyles = {
  streets: 'mapbox://styles/mapbox/streets-v9',
  satellite: 'mapbox://styles/mapbox/satellite-v9'
};

export default function MapComponent(props) {

  const { ownerProperties } = props;

  /** Map ref */
  const map = useRef();
  // filters parcelids
  const [parcelnumb, setParcelnumb] = useState(props.filterParcelIds || []);

  const [showWMS, setShowWMS] = useState(props.filterParcelIds == null);

  const [isDrawToolsActive, setIsDrawToolsActive] = useState(false);

  const [staticFeature, setStaticFeaute] = useState(null);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const [zipBoundary, setZipBoundary] = useState(null);

  const [pntGeoJson, setPntGeoJson] = useState({
    type: 'FeatureCollection',
    features: []
  });

  const [hoveredFeature, setHoveredFeature] = useState({
    type: 'FeatureCollection',
    features: []
  });

  const [viewport, setViewport] = useState({
    // 34.044902, -84.342369
    longitude: -84.342369,
    latitude: 34.044902,
    zoom: 13
  });

  const filterPnts = `parcelnumberraw IN (${parcelnumb
    .map((num) => `'${num}'`)
    .join(',')})`;

  // console.log("fietttttt", filter);
  useEffect(() => {
    // console.log('Filtered Parcelnumb IDs:', parcelnumb);
  }, [parcelnumb]);

  /** Basemaps */
  const [basemap, setBasemap] = useState(
    props.defaultBasemap || mapStyles.streets
  );
  const changeBasemap = () => {
    if (basemap === mapStyles.satellite) {
      setBasemap(mapStyles.streets);
    } else {
      setBasemap(mapStyles.satellite);
    }
  };

  /** Focused data:  Layer style*/

  const focusedParcelStyle = props.parcelStyle || {
    id: 'focused',
    type: 'fill',
    paint: {
      'fill-color':
        basemap === mapStyles.satellite ? 'rgba(0,0,0,0)' : '#C7BC9F',
      'fill-outline-color': '#67773F'
    }
  };

  /** Static Feature style style */
  const staticFeatureStyle = {
    id: 'static-feature',
    type: 'fill',
    paint: {
      'fill-color': 'rgba(4, 174, 196, 0.1)', // 50% transparent blue
      'fill-outline-color': 'rgb(4, 174, 196)'
    }
  };


  /** Popup state */
  const [showPopup, setShowPopup] = useState(false);
  const [popupLocation, setPopupLocation] = useState({
    latitude: 0,
    longitude: 0
  });

  const closePopup = () => setShowPopup(true);
  const [drawStarted, setDrawStarted] = useState(false);

  const [focusedParcel, setFocusedParcel] = useState({
    type: 'FeatureCollection',
    features: []
  });

  const [detailsParcelGeom, setDetailsParcelGeom] = useState(
    props.detailsParcelGeom || undefined
  );

  useEffect(() => {
    if (pntGeoJson.features) {
      setHoveredFeature(
        pntGeoJson.features.find(
          (f) =>
            f.properties.parcelno === props.hoverParcelId
        )
      );
    }
  }, [props.hoverParcelId]);

  useEffect(() => {
    if (!showWMS) {
      // showWFSData();
      setTimeout(() => {
        let _pntGeoJson = null;
        if (props.page === 'details') {
          // Single Property Details
          const property = JSON.parse(localStorage.getItem('propertyData'))
          if (property && property.latitude && property.longitude) {
            _pntGeoJson = {
              type: 'FeatureCollection',
              features: [{
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [+property.latitude, +property.longitude]
                },
                properties: {
                  parcelno: property.parcel_id
                }
              }]
            }
          }
        } else if (props.page === 'owner') {
          if (ownerProperties) {
            _pntGeoJson = {
              type: 'FeatureCollection',
              features: ownerProperties.filter(pr => pr.latitude != null && pr.longitude != null).map(property => ({
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [+property.latitude, +property.longitude]
                },
                properties: {
                  parcelno: property.parcel_id
                }
              }))
            }

          }
        }
        else {
          // All Filtered Properties
          const listingProperties = JSON.parse(localStorage.getItem('listingData')).parcel_ids;
          const _zipBoundary = JSON.parse(localStorage.getItem('listingData')).zipGeometry;
          if (_zipBoundary && _zipBoundary.length > 0) {
            setZipBoundary(parse(_zipBoundary));
            // console.log('parsed zip' ,parse(_zipBoundary));
          } else {
            setZipBoundary(null);
          }

          if (listingProperties) {
            _pntGeoJson = {
              type: 'FeatureCollection',
              features: listingProperties.filter(prop => prop.long != null && prop.lat != null).map(property => ({
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [+property.long, +property.lat]
                },
                properties: {
                  parcelno: property.parcelno
                }
              }))
            }
          }
        }
        if (_pntGeoJson) {

          setPntGeoJson(_pntGeoJson);

          if (props.page === 'owner' || (props.page === 'results')) {
            if (_pntGeoJson.features.length > 1) {
            const [minLng, minLat, maxLng, maxLat] = bbox(_pntGeoJson);

            const { innerWidth, innerHeight } = window;
            const padding = Math.min(innerWidth, innerHeight)
            map.current.fitBounds(
              [
                [minLng, minLat],
                [maxLng, maxLat]
              ],
              { padding: 50, duration: 1000 }
            );
          }
          }
        }
      }, 500)
    }
  }, []);

  useEffect(() => {
    if (!!focusedParcel.features.length && !isDrawToolsActive) {
      setShowPopup(true);
    }
  }, [popupLocation]);

  useEffect(() => {
    if (focusedParcel.features.length > 0) {
      const [minLng, minLat, maxLng, maxLat] = bbox(focusedParcel.features[0]);

      map.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat]
        ],
        { padding: 100, duration: 1000 }
      );

    }
  }, [focusedParcel])

  useEffect(() => {

    if ((props.page === 'details' && pntGeoJson.features.length > 0) || (props.page === 'results' && pntGeoJson.features.length === 1 )) {

      const lat = pntGeoJson.features[0].geometry.coordinates[1];
      const lng = pntGeoJson.features[0].geometry.coordinates[0];

      const size = 0.0001;

      var parameters = {
        service: 'WMS',
        version: '1.1.1',
        request: 'GetFeatureInfo',
        layers: 'rhinorecon:parcels',
        query_layers: 'rhinorecon:parcels',
        feature_count: 50,
        info_format: 'application/json',
        format_options: 'callback:handleJson',
        SrsName: 'EPSG:4326',
        width: 101,
        height: 101,
        x: 50,
        y: 50,
        bbox:
          lng -
          size +
          ',' +
          (lat - size) +
          ',' +
          (lng + size) +
          ',' +
          (lat + size)
      };
      axios
        .get(`https://geoserver.rhinorecon.com/geoserver/rhinorecon/wms`, {
          params: parameters
        })
        .then((res) => {
          setFocusedParcel(res.data);
        });
    }
  }, [props.page, pntGeoJson]);

  const handleClick = useCallback((event) => {

    event.preventDefault();
    if (!isDrawToolsActive) {
      const { lng, lat } = event.lngLat;
      const size = 0.0001;

      var parameters = {
        service: 'WMS',
        version: '1.1.1',
        request: 'GetFeatureInfo',
        layers: 'rhinorecon:parcels',
        query_layers: 'rhinorecon:parcels',
        feature_count: 50,
        info_format: 'application/json',
        format_options: 'callback:handleJson',
        SrsName: 'EPSG:4326',
        width: 101,
        height: 101,
        x: 50,
        y: 50,
        bbox:
          lng -
          size +
          ',' +
          (lat - size) +
          ',' +
          (lng + size) +
          ',' +
          (lat + size)
      };
      // const mapPadding = props.mapZoomPadding || 0.005;
      axios
        .get(`https://geoserver.rhinorecon.com/geoserver/rhinorecon/wms`, {
          params: parameters
        })
        .then((res) => {
          setFocusedParcel(res.data);
          const [minLng, minLat, maxLng, maxLat] = bbox(res.data);
          const { innerWidth, innerHeight } = window;
          const padding = Math.min(innerWidth, innerHeight)

          map.current.fitBounds(
            [
              [minLng, minLat],
              [maxLng, maxLat]
            ],
            {
              padding: 20,
              duration: 1000
            }
          );
          const _parcelCentroid = centroid(res.data);
          // console.log('_parcelCentroid', _parcelCentroid)
          setPopupLocation({ latitude: _parcelCentroid.geometry.coordinates[1], longitude: _parcelCentroid.geometry.coordinates[0] });
        });
    }
  });

  useEffect(() => {
    // map.fitBounds to the drawn features
    if (props.drawnFeatures && map.current) {
      const drawnFeatures = wellknown.parse(props.drawnFeatures);
      const [minLng, minLat, maxLng, maxLat] = bbox(drawnFeatures);
      const { innerWidth, innerHeight } = window;
      const padding = Math.min(innerWidth, innerHeight)

      map.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat]
        ],
        {
          padding: 50,
          duration: 1000
        }
      );
      props.handleSearchClick && props.handleSearchClick();

    }
  }, [props.drawnFeatures, map.current]);

  useEffect(() => {
    if (localStorage.getItem('drawnFeatures')) {
      setStaticFeaute(localStorage.getItem('drawnFeatures'));
    }
  }
    , []);


  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth <= 800); // Change the value according to your mobile screen breakpoint
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <div
      style={{
        width: '100%',
        height: showWMS
          ? 'calc(100vh - 21rem)'
          : props.page === 'details'
            ? 'calc(100vh - 25rem)'
            : 'calc(100vh - 28rem)'
      }}
    >
      <Map
        ref={map}
        initialViewState={viewport}
        style={{
          width: '100%',
          height: props.mapHeight ? props.mapHeight : '100%',
        }}
        mapStyle={basemap}
        onClick={!props.isStatic && handleClick}
        interactiveLayerIds={['parcels-wms-lyr']}
      >
        <NavigationControl position='bottom-right' />
        <GeolocateControl position='bottom-right'
          positionOptions={{ enableHighAccuracy: true }}
          trackUserLocation={false}
        />
        {!isMobileScreen && map && map.current && props.page !== 'details' && (
          <DrawControl
            map={map.current}
            controls={['polygon', 'circle']}
            drawnFeatures={props.drawnFeatures}
            setDrawnFeatures={props.setDrawnFeatures}
            isDrawToolsActive={isDrawToolsActive}
            setIsDrawToolsActive={setIsDrawToolsActive}
            handleSearch={props.handleSearchClick}
          />
        )}
        {showPopup && (
          <Popup
            longitude={popupLocation.longitude}
            latitude={popupLocation.latitude}
            anchor='bottom'
            onClose={() => {
              setShowPopup(false);
              setFocusedParcel({ type: 'FeatureCollection', features: [] });
            }}
          >
            <PopupContent
              feature={
                focusedParcel.features.length && focusedParcel.features[0]
              }
            />
          </Popup>
        )}
        {!props.hideParcelsLyr && (
          <Source
            id='parcels-wms'
            type='raster'
            tiles={[
              `https://geoserver.rhinorecon.com/geoserver/rhinorecon/wms?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=rhinorecon:parcels`
            ]}
            tileSize={256}
          >
            <Layer id='parcels-wms-lyr' type='raster' minzoom={10} />
          </Source>
        )}
        {showWMS && (
          <>
            <Source
              id='points-wms'
              type='raster'
              tiles={[
                `https://geoserver.rhinorecon.com/geoserver/rhinorecon/wms?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=rhinorecon:tbl_ga_data`
              ]}
              tileSize={256}
            >
              <Layer id='points-wms-lyr' type='raster' />
            </Source>
          </>
        )}
        {!showWMS && pntGeoJson && (
          <>
            <Source type='geojson' data={pntGeoJson}>
              <Layer
                id='wfs-layer'
                type='circle'
                paint={{
                  'circle-color': '#67773f',
                  'circle-radius': 7,
                  'circle-stroke-color': '#fff',
                  'circle-stroke-width': 2
                }}
              />
            </Source>

            <Source type='geojson' data={hoveredFeature}>
              {!props.isStatic && (
                <Layer
                  id='wfs-layer2'
                  type='circle'
                  paint={{
                    'circle-color': 'yellow',
                    'circle-radius': 10,
                    'circle-stroke-color': '#67773f',
                    'circle-stroke-width': 2
                  }}
                />
              )}
            </Source>
          </>
        )}
        {
          zipBoundary && (
            <Source type='geojson' data={zipBoundary}>
              <Layer
                id='zip-boundary'
                type='line'
                paint={{
                  'line-color': '#67773f',
                  'line-width': 2
                }}
              />
            </Source>
          )
        }
        {props.page === 'details' && pntGeoJson && (
          <>
            <Source type='geojson' data={pntGeoJson}>
              <Layer
                id='highlted-point'
                type='circle'
                paint={{
                  'circle-color': 'rgba(0,0,0,0)',
                  'circle-radius': 12,
                  'circle-stroke-color': 'yellow',
                  'circle-stroke-width': 2
                }}
              />
            </Source>
          </>
        )}
        {focusedParcel.features.length > 0 && (
          <Source type='geojson' data={focusedParcel}>
            <Layer
              {...focusedParcelStyle}
              beforeId={showWMS ? 'points-wms-lyr' : 'wfs-layer'}
            />
          </Source>
        )}
        {staticFeature && props.page === 'results' && (
          <Source type='geojson' data={parse(staticFeature)}>
            <Layer {...staticFeatureStyle} />
          </Source>
        )}
        {detailsParcelGeom && (
          <Source type='geojson' data={detailsParcelGeom}>
            <Layer {...focusedParcelStyle} />
          </Source>
        )}
      </Map>
      {
        props.page !== 'owner' &&
        <div className={styles.changeBasemap} onClick={changeBasemap}>
          <img
            src={basemap === mapStyles.satellite ? streetsIcon : satelliteIcon}
            alt='basemap'
          ></img>
        </div>
      }

    </div>
  );
}
